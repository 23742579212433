<template>
  <v-dialog v-model="_open" scrollable persistent :max-width="_maxWidth">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on" />
    </template>

    <v-card>
      <v-card-title style="display: block" class="pb-1 pr-8">
        <v-icon x-large class="mr-3">mdi-filter-multiple</v-icon>
        {{ title }}
        <v-btn
          @click="_open = false"
          style="position: absolute; right: 5px; top: 5px"
          icon
        >
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-0 form_container">
        <v-container>
          <v-row>
            <v-col
              v-for="({ autocomplete, items, itemValue, itemText, name, key, valueChanged }, k) in filterableCols"
              :cols="12"
              :key="k"
            >
              <v-label>{{ name }}</v-label>
              <v-autocomplete
                v-if="autocomplete"
                :append-icon="!items.length ? null : '$dropdown'"
                deletable-chips
                dense
                :disabled="!items.length"
                hide-details
                :items="items"
                :item-value="itemValue"
                :item-text="itemText"
                multiple
                outlined
                persistent-placeholder
                :placeholder="!items.length ? 'Nenhuma opção carregada' : 'Busque por termos'"
                small-chips
                :value="_value[key]"
                @input="updateValue(key, $event, valueChanged)"
              ></v-autocomplete>
              <input-chip
                v-else
                append-icon="mdi-magnify"
                hide-details
                placeholder="Separe termos por ; ou Enter"
                :value="_value[key]"
                @input="updateValue(key, $event, valueChanged)"
              ></input-chip>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-6 pb-3 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          class="pr-5"
          dark
          depressed
          @click="doClear()"
        >
          <v-icon left>mdi-filter-off-outline</v-icon>
          Limpar
        </v-btn>
        <v-btn
          color="primary"
          class="px-5 ml-3"
          depressed
          @click="doFilter()"
        >
          Filtrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    "input-chip": () => import("@/components/input-chip.vue"),
  },
  computed: {
    _open: {
      get() {
        return this.dialogOpen;
      },
      set(value) {
        this.dialogOpen = !!value;
        this.$emit("update:open", !!value);
      },
    },
    _maxWidth: function () {
      if (this.maxWidth) {
        return this.maxWidth;
      }
      
      return 'fit-content';
    },
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
        this.$emit("input", value);
        this.$emit("mutate", value);
      },
    },
    filterableCols: function () {
      return Object.values(this.fields).filter(({ filterable, hideInFilter }) => !!filterable && !hideInFilter);
    }
  },
  data: function () {
    return {
      dialogOpen: false,
    };
  },
  methods: {
    doClear: function () {
      this._value = {};
      this.$emit("clear");
      this._open = false;
    },
    doFilter: function () {
      this.$emit("filter", this._value);
      this._open = false;
    },
    updateValue: function (key, val, valueChanged) {
      this._value = {
        ...this._value,
        [key]: val,
      };

      if (valueChanged) {
        valueChanged(val);
      }
    },
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    maxWidth: [Number, String],
    title: {
      type: String,
      default: "Filtros",
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    opts: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    open: function (value) {
      this.dialogOpen = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__text.form_container {
  margin: -1.5rem 0;
}

.v-label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  padding: 0 0 .5rem .5rem;
  margin: 0;
}

.v-input.v-autocomplete::v-deep .v-input__control .v-input__slot {
  min-height: 44px;
}
</style>
